import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputAdornment, OutlinedInput, Popover } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import SortingIcon from "../assets/icons/SortingIcon";
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from "../assets/buttons/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { searchUsersSchema } from "../../validation/validationSchemas";

const SortableHeader = ({ fields, list, setFilteredList, classes, filters, dictionaryMapper, dictionary }) => {
  const [sortConfig, setSortConfig] = useState({ key: fields[0].key, direction: 'asc' });
  const [search, setSearch] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    register,
    handleSubmit,
    getFieldState,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(searchUsersSchema),
    mode: "onChange",
  });

  const isError = () => {
    return getFieldState("search").invalid;
  };

  const handleSortClick = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key) {
      direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
    }

    setSortConfig({ key, direction });
    sortList(list, key, direction);
  };

  const sortList = (list, key, direction) => {
    const sortedList = [...list].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    applyFiltersAndSearch(sortedList);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleFilterIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (filterKey, value) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilter = prevFilters[filterKey] || [];
      if (updatedFilter.includes(value)) {
        return {
          ...prevFilters,
          [filterKey]: updatedFilter.filter((v) => v !== value),
        };
      } else {
        return {
          ...prevFilters,
          [filterKey]: [...updatedFilter, value],
        };
      }
    });
  };

  const applyFiltersAndSearch = (listToFilter = list || []) => {
    if (!Array.isArray(listToFilter)) {
      console.error("listToFilter is not an array:", listToFilter);
      return;
    }
  
    let filteredList = listToFilter;
  
    Object.keys(selectedFilters).forEach((filterKey) => {
      const filterValues = selectedFilters[filterKey];
      if (filterValues.length > 0) {
        filteredList = filteredList.filter((item) => {
          const itemValues = item[filterKey];
          const itemValuesArray = Array.isArray(itemValues) ? itemValues : [];
          return filterValues.some(value => itemValuesArray.includes(value));
        });
      }
    });
  
    if (search) {
      filteredList = filteredList.filter(item =>
        Object.values(item).some(
          value =>
            typeof value === 'string' &&
            value.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  
    setFilteredList(filteredList);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    applyFiltersAndSearch();
    handleFilterClose();
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    setFilteredList(list);
  }, [list, setFilteredList]);

  useEffect(() => {
    applyFiltersAndSearch();
  }, [search, selectedFilters]);

  return (
    <div className={classes["header"]}>
      <form
        className={classes["search-form"]}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl
          className={classes["search-input-control"]}
          onChange={handleSearchChange}
          error={isError()}
        >
          <OutlinedInput
            size="small"
            value={search}
            name="search"
            type="text"
            {...register("search")}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />
          <FormHelperText>{errors?.search?.message}</FormHelperText>
        </FormControl>
      </form>
      <div className={classes["headers-row"]}>
        {fields.map((field) => (
          <div key={field.key} className={classes["header-column"]}>
            <Button
              id={field.key}
              className={`${classes["column-header-button"]}`}
              icon={<SortingIcon />}
              startIcon="end"
              onClick={() => handleSortClick(field.key)}
            >
              {field.label}
            </Button>
          </div>
        ))}

        {filters && Object.keys(filters).length > 0 && (
          <div className={classes["filters-container"]}>
            <Button onClick={handleFilterIconClick}>
              <FilterListIcon />
            </Button>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleFilterClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className={classes["filter-panel"]}>
                {Object.keys(filters).map((filterKey) => (
                  <div key={filterKey} className={classes["filter-group"]}>
                    {filters[filterKey].map((value) => (
                      <FormControlLabel
                        key={value}
                        control={
                          <Checkbox
                            checked={
                              selectedFilters[filterKey]?.includes(value) ||
                              false
                            }
                            onChange={() =>
                              handleFilterChange(filterKey, value)
                            }
                          />
                        }
                        label={dictionaryMapper.map(value)}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </Popover>
          </div>
        )}
      </div>
    </div>
  );
};

SortableHeader.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  list: PropTypes.array.isRequired,
  setFilteredList: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired, // Filters passed in format { filterKey: ["value1", "value2", ...] }
};

export default SortableHeader;
