export const navigationBarDictionary = {
    deviceListPageTitle: "Перелік пристроїв",
    devicesPageTitle: "Пристрої",
    schedulingPageTitle: "Дизайнер сценаріїв",
    currentUserTitle: "Поточний користувач",
    currentUserTooltipTitle: "Поточний користувач",
    adminTooltipTitle: "Адмін",
    devicesManagementTitle: "Управління пристроями",
    usersManagementTitle: "Управління користувачами",
    userMessagesTitle: "Повідомлення"
}