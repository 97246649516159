import "./App.css";
import { React  } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import DeviceList from "./components/pages/device-list/DeviceList";
import Devices from "./components/pages/devices/Devices";
import NavigationBar from "./components/pages/navigation/NavigationBar";
import NotFoundPage from "./components/pages/NotFoundPage";
import { links } from "./enums/links";
import Logout from "./components/pages/authentication/Logout";
import Login from "./components/pages/authentication/Login";
import Scheduling from "./components/pages/scheduling/Scheduling";
import EditSchedule from "./components/pages/scheduling/EditSchedule";
import EditDevice from "./components/pages/devices/EditDevice";
import useSetCurrentUser from "./components/hooks/useSetCurrentUserResult";
import DevicesManagement from "./components/pages/admin/DevicesManagement";
import { authHelper } from "./helpers/authHelper";
import CertificatesManagement from "./components/pages/admin/certificates-management/CertificatesManagement";
import DeviceMessagesManagement from "./components/pages/admin/device-messages-management/DeviceMessagesManagement";
import UserMessagesManagement from "./components/pages/admin/user-messages-management/UserMessagesManagement";
import UsersManagement from "./components/pages/admin/user-management/UsersManagement";
import UserMessages from "./components/pages/user-messages/UserMessages";
import { UserMessagesProvider } from "./components/context/UserMessagesContext";

function App() {
  const currentUserResult = useSetCurrentUser();

  return (
    <div className="App">
      <UserMessagesProvider>
        <Router>
          <NavigationBar currentUserResult={currentUserResult} />
          <Routes>
            <Route path="/" element={<DeviceList />} />

            <Route path={links.deviceListPath} element={<Devices />} />

            <Route path={links.devicesPath} element={<DeviceList />} />

            <Route path={links.schedulingPath} element={<Scheduling />} />

            <Route path={links.logOutPath} element={<Logout />} />

            <Route
              path={`${links.scheduleEditPath}/:id`}
              element={<EditSchedule />}
            />

            <Route
              path={`${links.deviceEditPath}/:id`}
              element={<EditDevice />}
            />

            <Route
              path={links.devicesManagementPath}
              element={
                currentUserResult.isAuthenticated &&
                authHelper.isAdmin(currentUserResult) ? (
                  <DevicesManagement />
                ) : (
                  <Navigate to={links.loginPath} />
                )
              }
            />

            <Route
              path={`${links.certificatesManagementPath}/:deviceId`}
              element={<CertificatesManagement />}
            />

            <Route
              path={`${links.deviceMessagesManagementPath}/:deviceId`}
              element={<DeviceMessagesManagement />}
            />

            <Route
              path={`${links.userMessagesManagementPath}/:username`}
              element={<UserMessagesManagement />}
            />

            <Route
              path={`${links.usersManagementPath}`}
              element={<UsersManagement />}
            />

            <Route
              path={`${links.userMessagesPath}`}
              element={<UserMessages />}
            />

            <Route path={links.loginPath} element={<Login />} />
            <Route path={links.logOutPath} element={<Logout />} />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </UserMessagesProvider>
    </div>
  );
}

export default App;
