import { useState } from "react";
import { Link } from "react-router-dom";
import classes from "../../css/navigation/navigationBar.module.css";
import buttonClasses from "../../css/assets/buttons/button.module.css";
import { navigationBarDictionary } from "../../../dictionary/ua/navigationBarDictionary";
import { links } from "../../../enums/links";
import Button from "../../assets/buttons/Button";
import NavigationLogoSelector from "../../assets/icons/NavigationLogoSelector";
import { authenticationDictionary } from "../../../dictionary/ua/authenticationDictionary";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import React from 'react';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { authHelper } from "../../../helpers/authHelper";
import Contacts from "./Contacts";
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const NavigationBar = ({ currentUserResult }) => {
  const [active, setActive] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navs = [
    {
      id: "deviceList",
      navTitle: navigationBarDictionary.deviceListPageTitle,
      link: links.deviceListLink,
    },
    {
      id: "devices",
      navTitle: navigationBarDictionary.devicesPageTitle,
      link: links.devicesLink,
    },
    {
      id: "sheduling",
      navTitle: navigationBarDictionary.schedulingPageTitle,
      link: links.schedulingLink,
    }
  ];

  const setActivityStatus = (id) => (active === id ? "active" : "");

  const getTooltipTitle = () => {
    if(authHelper.isAdmin(currentUserResult)) {
      return navigationBarDictionary.adminTooltipTitle;
    }

    return navigationBarDictionary.currentUserTooltipTitle;
  }

  return (
    <div className={classes.navigation}>
      <NavigationLogoSelector />
      {navs.map((item) => (
        <Link key={item.id} to={item.link}>
          <Button
            className={`${buttonClasses["nav-button"]} ${
              buttonClasses[setActivityStatus(item.id)]
            }`}
            onClick={() => setActive(item.id)}
          >
            {item.navTitle}
          </Button>
        </Link>
      ))}

      {currentUserResult.isAuthenticated &&
        authHelper.isAdmin(currentUserResult) && (
          <>
            <Link key="device-management" to={links.devicesManagementLink}>
              <Button
                className={`${buttonClasses["nav-button"]} ${
                  buttonClasses[setActivityStatus("devices-management")]
                }`}
                onClick={() => setActive("devices-management")}
              >
                {navigationBarDictionary.devicesManagementTitle}
              </Button>
            </Link>

            <Link key="users-management" to={links.usersManagementLink}>
              <Button
                className={`${buttonClasses["nav-button"]} ${
                  buttonClasses[setActivityStatus("users-management")]
                }`}
                onClick={() => setActive("users-management")}
              >
                {navigationBarDictionary.usersManagementTitle}
              </Button>
            </Link>
          </>
        )}

      {currentUserResult.isAuthenticated && (
        <div>
          <React.Fragment>
            <Box>
              <Tooltip title={getTooltipTitle()}>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  {authHelper.isAdmin(currentUserResult) ? (
                    <AdminPanelSettingsIcon
                      className={classes["account-box-icon"]}
                    ></AdminPanelSettingsIcon>
                  ) : (
                    <AccountBoxIcon
                      className={classes["account-box-icon"]}
                    ></AccountBoxIcon>
                  )}

                  {currentUserResult.currentUsername}
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              id="account-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={handleClose}
                className={classes["account-menu"]}
              >
                <Link
                  className={classes["logout-link"]}
                  key="logout-link"
                  to={links.logOutPath}
                >
                  <ListItemIcon>
                    <Logout />
                    {authenticationDictionary.logOutButtonText}
                  </ListItemIcon>
                </Link>
              </MenuItem>
            </Menu>
          </React.Fragment>
        </div>
      )}

      <Link key="user-messages" to={links.userMessagesLink}>
        <MailOutlineIcon
          className={`${buttonClasses["message-icon-nav-button"]} ${
            buttonClasses[setActivityStatus("user-messages")]
          }`}
          style={{ fontSize: 40, color: 'white' }}
          onClick={() => setActive("user-messages")}
        />
      </Link>

      <Contacts />
    </div>
  );
};

export default NavigationBar;
