import * as yup from "yup";
import { gsmModeSelect } from "../enums/scheduling/gsmModeSelect";
import { mp3PlaybackSelect } from "../enums/mp3PlaybackSelect";
import { radioModeSelect } from "../enums/scheduling/radioModeSelect";

export const addNewDeviceSchema = yup.object().shape({
  clientId: yup
    .string()
    .min(5, "Id пристрою повинен мати хоча б 5 символів")
    .max(255, "Id пристрою повинен мати максимум 255 символів")
    .matches(
      "^([A-Za-z0-9]{0,255})$",
      "Id пристрою повинен містити лише букви англійського алфавіту та цифри 0-9"
    )
    .required("Це поле обов'язкове"),
  name: yup
    .string()
    .max(255, "Назва пристрою повинна мати максимум 255 символів")
    .required("Введіть назву пристрою"),
  profile: yup
    .string()
    .max(255, "Профіль пристрою повинен мати максимум 255 символів")
    .required("Введіть профіль пристрою"),
});

export const addNewDeviceMessageSchema = yup.object().shape({
  message: yup
    .string()
    .required("Це поле обов'язкове")
});

export const addNewUserMessageSchema = (usersList) => {
  return yup.object().shape({
    message: yup
      .string()
      .required("Це поле обов'язкове"),
    usersList: yup
      .array()
      .test(
        "at-least-one-selected",
        "Ви повинні вибрати хоча б одного користувача",
        function () {
          const { usersList } = this.options.context;
          return usersList && usersList.some(user => user.selected);
        }
      )
  });
};

export const radioSilenceCommandInputSchema = yup.object().shape({
  radioSilenceMinutes: yup
    .number()
    .min(1, "Поле повинне містити тільки цифри")
    .positive("Значення має бути більше нуля")
    .typeError("Поле повинне містити тільки цифри")
});

export const scheduleCommandInputSchema = yup.object().shape({
  schedule: yup
    .string()
});

export const addScheduleInputSchema = yup.object().shape({
  name: yup.string().required("Поле обов'язкове")
});

export const gsmAudioModeCommandInputSchema = yup.object().shape({
  gsmAudioMode: yup
    .number()
    .typeError("Поле повинне містити число")
    .min(0, "Поле повинне мати значення від 0 до 2")
    .max(2, "Поле повинне мати значення від 0 до 2")
});

export const radioAudioModeCommandInputSchema = yup.object().shape({
  radioAudioMode: yup
    .number()
    .typeError("Поле повинне містити число")
    .min(0, "Поле повинне мати значення від 0 до 2")
    .max(2, "Поле повинне мати значення від 0 до 2")
});

export const relayCommandInputSchema = yup.object().shape({
  relay: yup
    .number()
    .typeError("Поле повинне містити число")
    .min(0, "Поле повинне мати значення від 0 до 1")
    .max(1, "Поле повинне мати значення від 0 до 1")
});

export const loginSchema = yup.object().shape({
  username: yup
    .string()
    .required("Поле обов'язкове"),
  password: yup
    .string()
    .required("Поле обов'язкове")
});

export const dialFromNGsmToPhoneNumberSchema = yup.object().shape({
  gsmModule: yup
    .number()
    .typeError("Поле повинне містити число")
    .min(1, "Поле повинне мати значення від 1 до 3")
    .max(3, "Поле повинне мати значення від 1 до 3")
    .required("Поле обов'язкове"),
  phoneNumber: yup
    .string()
    .matches("^(?:\\+38)?\\s?0\\d{9}$", "Поле має неправильний формат")
    .required("Поле обов'язкове"),
});

export const hangUpActiveCallForGsmModuleSchema = yup.object().shape({
  gsmModule: yup
    .number()
    .typeError("Поле повинне містити число")
    .min(1, "Поле повинне мати значення від 1 до 3")
    .max(9999, "Поле повинне мати значення від 1 до 3"),
});

export const radioSilenceForNMinutesSchema = yup.object().shape({
  minutes: yup
    .number()
    .typeError("Поле повинне містити число")
    .min(1, "Поле повинне мати значення від 1 до 9999")
    .max(9999, "Поле повинне мати значення від 1 до 9999"),
});

export const radioSilenceUntilDatetimeSchema = yup.object().shape({
  datetime: yup.string().required("Поле обов'язкове")
});

export const radioSilenceUntilTimestampSchema = yup.object().shape({
  timestamp: yup.string().required("Поле обов'язкове"),
});

export const repeatNTimesSchema = yup.object().shape({
  times: yup
    .number()
    .typeError("Поле повинне містити число")
    .min(0, "Поле повинне мати значення від 0 до 999999")
    .max(999999, "Поле повинне мати значення від 0 до 999999"),
});

export const setGsmModeSchema = yup.object().shape({
  gsmMode: yup.string().required("Поле обов'язкове"),
  mp3Playback: yup.string().when("gsmMode", {
    is: gsmModeSelect.mp3Playback.value,
    then: (schema) => schema.required("Виберіть значення"),
    otherwise: (schema) => schema,
  }),
  trackNumber: yup.string().when(['gsmMode', 'mp3Playback'], {
    is: (gsmMode, mp3Playback) => gsmMode === gsmModeSelect.mp3Playback.value && mp3Playback === mp3PlaybackSelect.trackNumber.value,
    then: (schema) => schema.required("Введіть номер треку"),
    otherwise: (schema) => schema,
  }),
});

export const setMp3FileNumberForGsmPlaybackSchema = yup.object().shape({
  mp3FileNumber: yup.string().required("Поле обов'язкове"),
});


export const setMp3FileNumberForRadioPlaybackSchema = yup.object().shape({
  mp3FileNumber: yup.string().required("Поле обов'язкове"),
});

export const setRadioChannelForFirstTransceiverSchema = yup.object().shape({
  channel: yup
    .number()
    .typeError("Поле повинне містити число")
    .min(1, "Поле повинне мати значення від 1 до 128")
    .max(128, "Поле повинне мати значення від 1 до 128"),
});

export const setRadioChannelForSecondTransceiverSchema = yup.object().shape({
  channel: yup
    .number()
    .typeError("Поле повинне містити число")
    .min(1, "Поле повинне мати значення від 1 до 128")
    .max(128, "Поле повинне мати значення від 1 до 128"),
});

export const setRadioChannelForBothTransceiversSchema = yup.object().shape({
  channel: yup
    .number()
    .typeError("Поле повинне містити число")
    .min(1, "Поле повинне мати значення від 1 до 128")
    .max(128, "Поле повинне мати значення від 1 до 128"),
});

export const setRadioModeSchema = yup.object().shape({
  radioMode: yup.string().required("Поле обов'язкове"),
  mp3Playback: yup.string().when("radioMode", {
    is: radioModeSelect.mp3Playback.value,
    then: (schema) => schema.required("Виберіть значення"),
    otherwise: (schema) => schema,
  }),
  trackNumber: yup.string().when(['radioMode', 'mp3Playback'], {
    is: (radioMode, mp3Playback) => radioMode === radioModeSelect.mp3Playback.value && mp3Playback === mp3PlaybackSelect.trackNumber.value,
    then: (schema) => schema.required("Введіть номер треку"),
    otherwise: (schema) => schema,
  }),
});

export const waitForNSecondsSchema = yup.object().shape({
  seconds: yup
    .number()
    .typeError("Поле повинне містити число")
    .min(1, "Поле повинне мати значення від 1 до 999999")
    .max(999999, "Поле повинне мати значення від 1 до 999999"),
});

export const waitUntilDatetimeSchema = yup.object().shape({
  datetime: yup.string().required("Поле обов'язкове"),
});

export const waitUntilIncomingCallFromPhoneNumberSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .matches("^(?:\\+38)?\\s?0\\d{9}$", "Поле має неправильний формат")
    .required("Поле обов'язкове"),
});

export const waitFromN_to_M_secondsSchema = yup.object().shape({
  fromSeconds: yup
    .number()
    .typeError("Поле повинне містити число")
    .min(1, "Поле повинне мати значення від 1 до 999999")
    .max(999999, "Поле повинне мати значення від 1 до 999999"),
  toSeconds: yup
    .number()
    .typeError("Поле повинне містити число")
    .min(1, "Поле повинне мати значення від 1 до 999999")
    .max(999999, "Поле повинне мати значення від 1 до 999999")
    .test('is-greater', 'Значення "до" повинне бути більшим за значення "від"', function(value) {
      const { fromSeconds } = this.parent;
      return value > fromSeconds;
  }),
});

export const waitUntilTimestampSchema = yup.object().shape({
  timestamp: yup.string().required("Поле обов'язкове"),
});

export const searchSchedulesSchema = yup.object().shape({
  search: yup.string()
});

export const searchUsersSchema = yup.object().shape({
  search: yup.string()
});