import { axiosClient, handleApiCall } from "../axios/axiosConfig";

export const userMessagesApi = {
  create: async function (userMessage, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/userMessages`,
          method: "POST",
          data: userMessage,
        }),
      redirect
    );
  },
  createMany: async function (userMessage, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/userMessages/many`,
          method: "POST",
          data: userMessage,
        }),
      redirect
    );
  },
  update: async function (userMessage, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/userMessages`,
          method: "PUT",
          data: userMessage,
        }),
      redirect
    );
  },
  getByUsername: async function (username, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/userMessages/username/${username}`,
          method: "GET",
        }),
      redirect
    );
  },
  getForUser: async function (redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/userMessages`,
          method: "GET",
        }),
      redirect
    );
  },
  getById: async function (id, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/userMessages/${id}`,
          method: "GET",
        }),
      redirect
    );
  },
  delete: async function (id, redirect) {
    return handleApiCall(
      () =>
        axiosClient.request({
          url: `api/userMessages/${id}`,
          method: "DELETE",
        }),
      redirect
    );
  },
};
